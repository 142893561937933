/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InvoicesNonReturnables from "./invoiceNonreturnables";

const InvoicesNonReturnableListform = (props) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [tableDataForm, setTableData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  useEffect(() => {
    handleFetchInvoice();
  }, []);

  const handleFetchInvoice = async () => {
    try {
      const response = await fetch(
        `https://www.mapatrawala.com/inventory/api/NRetinvoice/${props.newsaleorderId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      // Extract the invoice byte data
      const invoiceByteData = data?.invoiceByteData;
      if (invoiceByteData) {
        // Convert byte array to string
        const binaryString = String.fromCharCode.apply(null, invoiceByteData);
        // Convert binary string to Base64
        const base64Invoice = btoa(binaryString);
        // Now you can use 'base64Invoice' as needed
        console.log(base64Invoice);
      }

      setTableData(data);
      // Check if props.setDocData is a function before calling it
      // if (typeof props.setDocData === 'function') {
      //   props.setDocData(data);
      // } else {
      //   console.error("setDocData is not a function");
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (index) => {
    const selectedItem = tableDataForm[index];
    setSelectedItemId(selectedItem.soiId);

    initialValues.serviceNumber = selectedItem.serviceNumber;
    initialValues.description = selectedItem.description;
    initialValues.rate = selectedItem.rate;
    initialValues.hsnSac = selectedItem.hsnSac;
    initialValues.amount = selectedItem.amount;
    initialValues.status = selectedItem.status;
    initialValues.remarks = selectedItem.remarks;

    setEditIndex(index);
    setEditMode(true);
  };

  const handleDelete = (index) => {
    const updatedData = [...tableDataForm];
    updatedData.splice(index, 1);
    setTableData(updatedData);
  };

  const initialValues = {
    materialNo: "",
    description: "",
    quantity: "",
    rate: "",
    hsnSac: "",
    amount: "",
    status: "",
  };

  const checkoutSchema = yup.object().shape({
    materialNo: yup
      .string()
      .matches(/^\d+$/, "Material No must contain only digits")
      .min(3, "Material No must be at least 3 digit")
      .max(7, "Material No must be at most 6 digits")
      .required("Required"),

    description: yup
      .string()
      .max(100, "Description must be at most 100 characters")
      .required("Required"),

    quantity: yup
      .string()
      .max(20, "Quantity should be at most 20 digits")
      .required("Required"),

    hsnSac: yup
      .string()
      .matches(/^\d+$/, "HAS/SAC must contain only digits")
      .min(3, "HAS/SAC must be at least 3 digit")
      .max(10, "Quantity should be at most 10 digits")
      .required("HAS/SAC is required"),
    status: yup.string().required("status is required"),

    rate: yup
      .string()
      .max(20, "Rate should be at most 20 digits")
      .required("Amount is required"),
  });

  return (
    <Box m="20px">
      {/* Display the PDF inside an iframe */}
      <iframe
        src={`data:application/pdf;base64,${tableDataForm.invoicepdf}`}
        style={{ width: "100%", height: "500px" }}
        frameBorder="0"
      ></iframe>

      {/* Your other JSX components */}
    </Box>
  );
};

export default InvoicesNonReturnableListform;
