import React from "react";

const Report = () => {

    return(

        <div>
            <h1>Reports Page</h1>
        </div>
    );
}

export default Report;