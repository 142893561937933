/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import Header from "../../components/Header";

const AdminPurchaseRequestList = (props) => {
  const [tableDataForm, setTableData] = useState([]);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchingData();
  }, []);

  const fetchingData = async () => {
    try {
      const response = await fetch(
        "https://www.mapatrawala.com/inventory/api/warehouse/allitems"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log(data);
      setTableData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleApproved = (index) => {
    // Handle approval logic here, you can use the index or row data to identify the item
    console.log("Approved item at index:", index);
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(tableDataForm);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "purchase_requests.xlsx"
    );
  };

  return (
    <div style={{ margin: "20px" }}>
      <Box mt="20px">
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title="PURCHASE REQUEST LIST" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              variant="contained"
              color="primary"
              onClick={exportToExcel}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Export
            </Button>
          </Box>
        </Box>

        <TableContainer component={Paper} mt={2}>
          <Table>
            <TableHead style={{ backgroundColor: "#4379BF" }}>
              <TableRow>
                <TableCell>MaterialNo</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Amount</TableCell>
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDataForm.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.materialNo}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>{row.rate}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  {/* <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleApproved(index)}
                  >
                    Approve
                  </Button>
                </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default AdminPurchaseRequestList;
